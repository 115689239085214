import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from './include/Navbar';

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const EmailConfirmation = () => {
    const { uidb64, token } = useParams();
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const cookie = getCookie('csrftoken');
        fetch(`${process.env.REACT_APP_API_URL}api/users/email-confirm/${uidb64}/${token}/`, {
            method: 'GET',  // Typically, email confirmation is done with a GET request
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': cookie
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                setMessage(data.message);
                // Navigate or show a success message after confirmation
                setTimeout(() => {
                    navigate('/login'); // Redirect to login after confirmation
                }, 3000);
            } else {
                setMessage('Error confirming email: ' + (data.error || 'Unknown error'));
            }
        })
        .catch(error => {
            setMessage('Error: ' + error);
        });
    }, [uidb64, token, navigate]);

    return (
        <>
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col s12 card">
                        <div className="container">
                            <h3 className="center">Email Confirmation</h3>
                            <div className="row">
                                <div className="col s12">
                                    <p className="center">{message || 'Confirming your email...'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailConfirmation;
