import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import tinycolor from 'tinycolor2';
import autoTable from 'jspdf-autotable';

const loadImage = (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous'; // Enable CORS if necessary
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            resolve({ dataURL, width: img.width, height: img.height });
        };
        img.onerror = (err) => {
            reject(err);
        };
        img.src = src;
    });
};

const CaptureMapImage = ({
    mapTitle,
    showNorthArrow,
    logoUrl,
    description,
    rasters,
    vectors,
}) => {
    const captureMapImage = async () => {
        const mapElement = document.querySelector('.leaflet-container');
        const zoomControl = document.querySelector('.leaflet-control-zoom');

        if (mapElement) {
            // Esconder o controle de zoom
            if (zoomControl) {
                zoomControl.style.display = 'none';
            }
            // Optional: Adjust map element size to improve capture quality
            mapElement.style.width = '1200px';
            mapElement.style.height = '800px';

            // Increase the scale to capture the map at a higher resolution
            const canvas = await html2canvas(mapElement, { useCORS: true, scale: 3 });
            const imgData = canvas.toDataURL('image/png');

            // Reset map element size
            mapElement.style.width = '';
            mapElement.style.height = '';
            zoomControl.style.display = '';
            // Initialize jsPDF in landscape mode to fit the content
            const pdf = new jsPDF('landscape', 'pt', 'a4');

            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            // Define margins
            const marginLeft = 40;
            const marginRight = 40;
            const marginTop = 60;
            const marginBottom = 60;

            // Calculate content width and height
            const contentWidth = pageWidth - marginLeft - marginRight;
            const contentHeight = pageHeight - marginTop - marginBottom;

            // Define column widths
            const leftColumnWidth = (contentWidth * 3) / 4; // 75% for the map
            const rightColumnWidth = contentWidth / 4; // 25% for the right side

            let currentY = marginTop;

            // Add Title at the top center
            if (mapTitle) {
                pdf.setFontSize(25);
                pdf.setFont('helvetica', 'bold');
                pdf.text(mapTitle, pageWidth / 2, currentY, { align: 'center' });
                currentY += 30;

                // Divider Line after Title
                pdf.setLineWidth(0.5);
                pdf.line(marginLeft, currentY, pageWidth - marginRight, currentY);
                currentY += 10;
            }

            // Positions for left and right columns
            const leftColumnX = marginLeft;
            const rightColumnX = leftColumnX + leftColumnWidth + 15; // Increased padding

            // Map Image Dimensions
            let mapWidth = leftColumnWidth;
            let mapHeight = (canvas.height * mapWidth) / canvas.width;
            
            // Adjust map height if it exceeds content height
            if (mapHeight > contentHeight - (currentY - marginTop)) {
                mapHeight = contentHeight - (currentY - marginTop);
                mapWidth = (canvas.width * mapHeight) / canvas.height;
            }

            // Add Map Image to the left column
            pdf.addImage(imgData, 'PNG', leftColumnX, currentY, mapWidth, mapHeight);

            // Add North Arrow on top of the Map
            if (showNorthArrow) {
                try {
                    const { dataURL: northArrowImgDataUrl } = await loadImage(process.env.PUBLIC_URL + '/norte.png');
                    const arrowWidth = 50;
                    const arrowHeight = 50;
                    const arrowX = leftColumnX + mapWidth - arrowWidth - 10; // Top-right corner of the map
                    const arrowY = currentY + 10;
                    pdf.addImage(northArrowImgDataUrl, 'PNG', arrowX, arrowY, arrowWidth, arrowHeight);
                } catch (error) {
                    console.error('Error loading north arrow image:', error);
                }
            }

            // Add a vertical line dividing the map from the content
            pdf.setLineWidth(0.5);
            const lineX = leftColumnX + leftColumnWidth + 5; // Line position between columns
            pdf.line(lineX, currentY, lineX, currentY + mapHeight); // Vertical line alongside the map

            // Start position for the right column content
            const contentStartY = currentY; // Start content at the same Y as the map
            let rightColumnY = contentStartY;

            const rightColumnPadding = 15;

            const centerX = (elementWidth) => rightColumnX + (rightColumnWidth - elementWidth) / 2;

            // Add Logo to the right column
            if (logoUrl) {
                try {
                    const { dataURL: logoImgDataUrl, width: imgWidth, height: imgHeight } = await loadImage(logoUrl);

                    let logoWidth = imgWidth;
                    let logoHeight = imgHeight;

                    // Maximum allowed dimensions for the logo
                    const maxLogoWidth = rightColumnWidth - 2 * rightColumnPadding; // Account for padding
                    const maxLogoHeight = 100;

                    // Scale down if larger than maximum
                    if (logoWidth > maxLogoWidth || logoHeight > maxLogoHeight) {
                        const widthRatio = maxLogoWidth / logoWidth;
                        const heightRatio = maxLogoHeight / logoHeight;
                        const scale = Math.min(widthRatio, heightRatio);
                        logoWidth *= scale;
                        logoHeight *= scale;
                    }

                    // Scale down to 75% of its size
                    logoWidth *= 0.75;
                    logoHeight *= 0.75;

                    const logoX = centerX(logoWidth);

                    // Add the logo image
                    pdf.addImage(logoImgDataUrl, 'PNG', logoX, rightColumnY, logoWidth, logoHeight);
                    rightColumnY += logoHeight + 20; // Increase space after logo
                } catch (error) {
                    console.error('Error loading logo image:', error);
                }
            }

            // **Add Space Between Logo and Description**
            rightColumnY += 10;

            // Add Description to the right column
            if (description) {
                pdf.setFontSize(12);
                pdf.setFont('helvetica', 'bold');
                const descriptionTitle = 'Description';
                const descriptionTitleWidth = pdf.getTextWidth(descriptionTitle);
                pdf.text(descriptionTitle, rightColumnX, rightColumnY);
                rightColumnY += 15;
            
                pdf.setFontSize(12);
                pdf.setFont('helvetica', 'normal');
            
                const textWidth = rightColumnWidth - 2 * rightColumnPadding;
                const textLines = pdf.splitTextToSize(description, textWidth);
            
                // Draw a rectangle as the background for the text (optional)
                // pdf.rect(rightColumnX, rightColumnY, textWidth, textHeight, 'S');
            
                // Set the text alignment to 'left'
                pdf.text(textLines, rightColumnX, rightColumnY, { align: 'left' });
                rightColumnY += textLines.length * 12 + 20; // Approximate height increment
            }

            // **Add Space Between Description and Legend**
            rightColumnY += 10;

            // **Generate Legend Dynamically from Map Data**
            // Function to convert hex color to RGB array
            function getColorRgb(colorStr) {
                const color = tinycolor(colorStr);
                if (color.isValid()) {
                    const { r, g, b } = color.toRgb();
                    return [r, g, b];
                } else {
                    // Default to black if the color is invalid
                    return [0, 0, 0];
                }
            }

            // Build legend items from vectors
            console.log("Vectors Legends", vectors);
            const legendItems = vectors
                .filter((vector) => vector.visible)
                .map((vector) => {
                    // Get the name from vector.data.properties.name or vector.data.properties.id
                    const label = vector.data.properties?.name || vector.data.properties?.id || 'Unnamed Layer';

                    // Get the first feature's style
                    const firstFeature = vector.data.features[0];

                    const style = firstFeature?.style || {};

                    // Get color from style
                    const colorStr = style.fillColor || style.color || style.strokeColor || '#000000';
                    const rgbColor = getColorRgb(colorStr);
                    console.log('Style:', style);
                    console.log('Color:', colorStr);
                    console.log('RGB:', rgbColor);
                    // Determine geometry type
                    let geometryType = 'Unknown';
                    if (firstFeature && firstFeature.geometry && firstFeature.geometry.type) {
                        geometryType = firstFeature.geometry.type;
                    }

                    return { label, color: rgbColor, type: geometryType };
                });

            // Add Legend to the right column
            const legendLeftPadding = rightColumnPadding;

            // Add Legend to the right column
            if (legendItems.length > 0) {
                pdf.setFontSize(12);
                pdf.setFont('helvetica', 'bold');
                const legendTitle = 'Legend';
                pdf.text(legendTitle, rightColumnX, rightColumnY);
                rightColumnY += 20;

                pdf.setFont('helvetica', 'normal');
                legendItems.forEach((item) => {
                    const symbolX = rightColumnX;
                    const labelX = symbolX + 15; // 10pt for symbol and 5pt space

                    // Draw symbol based on item.type
                    pdf.setFillColor(...item.color);
                    if (item.type.includes('Point')) {
                        // Draw a circle
                        pdf.circle(symbolX + 5, rightColumnY - 5, 5, 'F');
                    } else if (item.type.includes('LineString')) {
                        // Draw a line
                        pdf.setLineWidth(2);
                        pdf.line(symbolX, rightColumnY - 5, symbolX + 10, rightColumnY - 5);
                    } else if (item.type.includes('Polygon')) {
                        // Draw a rectangle
                        pdf.rect(symbolX, rightColumnY - 10, 10, 10, 'F');
                    } else {
                        // Default symbol
                        pdf.rect(symbolX, rightColumnY - 10, 10, 10, 'F');
                    }

                    // Add label next to the symbol
                    pdf.setFontSize(10);
                    pdf.text(item.label, labelX, rightColumnY);
                    rightColumnY += 15;
                });
            }

            // Add a horizontal line below the map
            const bottomOfMap = currentY + mapHeight;
            const bottomOfContent = rightColumnY;
            const maxBottomY = Math.max(bottomOfMap, bottomOfContent);

            // Add margin between bottom of map and horizontal line
            const lineY = bottomOfMap + 10; // 10pt margin after map
            pdf.setLineWidth(0.5);
            pdf.line(marginLeft, lineY, pageWidth - marginRight, lineY); // Draw horizontal line

            // Update currentY after the line
            currentY = lineY + 10; // 10pt margin after line

            // Adjust currentY to be at least as far down as the content
            currentY = Math.max(currentY, bottomOfContent + 10);

            // Add bottom margin
            currentY += marginBottom;

            // Save the PDF
            pdf.save('Mapa_Personalizado.pdf');
        } else {
            console.error('Map element not found');
        }
    };
    return captureMapImage;
};
export default CaptureMapImage;