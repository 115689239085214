// useDownloadSelected.js
import { useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL;

const useDownloadSelected = (geojsonLayerRefs, selectedFeatures) => {
    const handleDownloadSelected = useCallback(async (geojson) => {
        console.log('Downloading selected features useDownloadSelector', geojson);
        const id = geojson.data.properties.id;
        const token = Cookies.get('access_token');

        const filteredFeatures = selectedFeatures.filter((featureId) => {
            const layer = geojsonLayerRefs.current[id];
            console.log('Layer useDownloadSelector', layer);
            if (layer) {
                const feature = layer.toGeoJSON().features.find(f => f.id === featureId || f.properties.id === featureId);
                return !!feature;
            }
            return false;
        });
        console.log('Filtered features useDownloadSelector', filteredFeatures);
        if (filteredFeatures.length === 0) {
            console.warn('No selected features to download for this layer.');
            return;
        }

        try {
            const response = await axios.post(
                `${API_URL}api/main/download-selected/${id}/`,
                { selectedFeatures: filteredFeatures },
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    responseType: 'blob',
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `selected_features_${id}.geojson`);
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error downloading the selected features', error);
        }
    }, [geojsonLayerRefs, selectedFeatures]);

    return handleDownloadSelected;
};

export default useDownloadSelected;
