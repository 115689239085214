import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL;

const useDownloadRaster = () => {
    const handleDownload = async (rasterfile) => {
        const id = rasterfile.data.id;
        const token = Cookies.get('access_token');
        try {
            const response = await axios.get(
                `${API_URL}api/main/download-raster/${id}/`,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    responseType: 'blob',
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `raster_${id}.tif`);
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the raster file', error);
        }
    };
    return handleDownload;
};

export default useDownloadRaster;
