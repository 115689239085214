import html2canvas from 'html2canvas';
import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL;

const TakeScreenshot = ({
    mapInstance,
    projectid,
    count,
    setCount
}) => {
    const takeScreenshot = () => {
        setCount(count + 1)
        if (mapInstance) {
            html2canvas(
                document.body, {
                scale: 2,
                useCORS: true,
            }

            ).then(canvas => {
                const imgData = canvas.toDataURL('image/png');

                const token = Cookies.get('access_token');
                const response = axios.post(
                    `${API_URL}api/main/update-project-thumbnail/${projectid}/`,
                    { thumbnail: imgData },
                    {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${token}`,
                        }
                    }
                )
                    .then(response => {
                        console.log('Thumbnail updated successfully');
                    })
                    .catch(error => {
                        console.error('Error updating thumbnail', error);
                    });

            });
        }
    };
    return takeScreenshot;
};

export default TakeScreenshot;