import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
  Container, 
  TextField, 
  Button, 
  Typography, 
  Box, 
  Alert 
} from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/';
const url = process.env.PUBLIC_URL;

function ResetPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const { isAuthenticated, user, loading } = useSelector(state => state.user);

  const handleSendEmailReset = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('email', email);

    try {
      const response = await axios.post(
        `${API_URL}api/users/reset-password/`,
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setSuccess("An email will be sent to your address, please check your inbox.");
      setError("");
      setEmail("");
      
      setTimeout(() => navigate("/"), 5000); // Navigate after 5 seconds

    } catch (error) {
      setError(error.response.data.error);
      setSuccess("");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box 
        component="form" 
        onSubmit={handleSendEmailReset} 
        sx={{
          mt: 4,
          p: 3,
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: '#f9f9f9',
        }}
      >
        {/* Logo */}
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            mb: 2,
            cursor: 'pointer' 
          }}
          onClick={() => navigate("/")} // Navigate to homepage on click
        >
          <img 
            // src="/path-to-your-logo/logo.png" // Replace with the path to your logo
            src={url + "/logo-world.svg"} 
            alt="Logo"
            style={{ height: 80 }} // Adjust the size as needed
          />
        </Box>

        <Typography variant="h5" gutterBottom>
          Reset Your Password
        </Typography>

        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

        <TextField
          fullWidth
          label="Email Address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          variant="outlined"
          margin="normal"
        />

        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          fullWidth
          sx={{ mt: 2 }}
        >
          Send Reset Email
        </Button>
      </Box>
    </Container>
  );
}

export default ResetPassword;
