import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
  IconButton,
  Box,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { MapContainer, TileLayer, ImageOverlay, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const MapConfigModal = ({
  open,
  onClose,
  onApply,
  currentMapState,
  rasters,
  vectors,
}) => {
  const [title, setTitle] = useState('');
  const [showNorthArrow, setShowNorthArrow] = useState(false);
  const [previewCenter, setPreviewCenter] = useState(currentMapState.center);
  const [previewZoom, setPreviewZoom] = useState(currentMapState.zoom);
  const [logoUrl, setLogoUrl] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (open) {
      setPreviewCenter(currentMapState.center);
      setPreviewZoom(currentMapState.zoom);
    }
  }, [open, currentMapState.center, currentMapState.zoom]);

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogoUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleApply = () => {
    onApply({ title, showNorthArrow, previewCenter, previewZoom, logoUrl, description });
    onClose();
  };

  const vectorStyle = (feature, vector) => {
    return vector.style || feature.style || {};
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          height: '80vh', // Ajuste conforme necessário
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Configurar Mapa
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ flex: 1, p: 2 }}>
        <Grid container spacing={2} sx={{ height: '100%' }}>
          {/* Seção Esquerda: Controles */}
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Título do Mapa"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              variant="outlined"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showNorthArrow}
                  onChange={(e) => setShowNorthArrow(e.target.checked)}
                  color="primary"
                />
              }
              label="Adicionar Seta de Norte"
            />
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Upload do Logo
              </Typography>
              <Button variant="contained" component="label" fullWidth>
                Selecionar Arquivo
                <input type="file" accept="image/*" hidden onChange={handleLogoUpload} />
              </Button>
              {logoUrl && (
                <Box mt={2} display="flex" justifyContent="center">
                  <img src={logoUrl} alt="Logo preview" style={{ width: '100%', maxWidth: '150px' }} />
                </Box>
              )}
            </Box>
            <TextField
              label="Descrição do Mapa"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>

          {/* Seção Direita: Mapa */}
          <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="subtitle1" gutterBottom>
              Pré-visualização do Mapa
            </Typography>
            <Box sx={{ flex: 1, position: 'relative' }}>
              <MapContainer
                center={previewCenter}
                zoom={previewZoom}
                zoomControl={false}
                style={{ height: '100%', width: '100%' }}
                whenCreated={(map) => {
                  map.on('moveend', () => {
                    setPreviewCenter(map.getCenter());
                    setPreviewZoom(map.getZoom());
                  });
                }}
              >
                <TileLayer url={currentMapState.tileLayerUrl} crossOrigin="" />
                {rasters.map((rasterdata, index) => {
                  const raster = rasterdata.data;
                  return rasterdata.visible && (
                    <ImageOverlay
                      url={raster.png}
                      bounds={rasterdata.bounds}
                      opacity={rasterdata.style.opacity}
                      zIndex={1000}
                      key={index}
                    />
                  );
                })}
                {vectors.map((vector, index) => (
                  vector.visible && (
                    <GeoJSON
                      key={`vector-${index}`}
                      data={vector.data}
                      style={(feature) => vectorStyle(feature, vector)}
                      pointToLayer={(feature, latlng) => {
                        if (feature.geometry.type === 'Point' || feature.geometry.type === 'MultiPoint') {
                          return L.circleMarker(latlng, vectorStyle(feature, vector));
                        }
                        return L.marker(latlng);
                      }}
                    />
                  )
                ))}
              </MapContainer>
            </Box>
            <Typography variant="body2" color="textSecondary">
              Você pode ajustar a posição do mapa acima para pré-visualização.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleApply} color="primary" variant="contained">
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapConfigModal;
