// useFeatureHandler.js
import { useEffect } from 'react';

const useFeatureHandler = ({
  vectors,
  layerRefs,
  setSelectedFeatures,
  setSelectedFeatureAttributes,
  setModalData,
  setModalFeatureId,
  setIsModalOpen,
}) => {

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const getVectors = () => vectors;

  const getModalData = (vectorId, featureId) => {
    const vectors = getVectors();
    const vectorData = vectors.find(v => v.data.properties.id === vectorId);
    if (vectorData && vectorData.data && vectorData.data.features) {
      const feature = vectorData.data.features.find(f => f.id === featureId);
      if (feature) {
        return feature.properties.attributes;
      }
    }
    return null;
  };

  const handleClickFeature = (vector, feature, layer, e) => {
    const isCtrlPressed = e.originalEvent.ctrlKey;
    const featureId = feature.id;

    if (isCtrlPressed) {
      setSelectedFeatures(prevSelectedFeatures => {
        const isSelected = prevSelectedFeatures.includes(featureId);

        if (isSelected) {
          layer.setStyle({ color: feature.style.color });
          return prevSelectedFeatures.filter(id => id !== featureId);
        } else {
          layer.setStyle({ color: 'yellow' });
          return [...prevSelectedFeatures, featureId];
        }
      });
    }

    if (!isCtrlPressed && feature.properties.attributes) {
      // TODO: Preste atenção, isso pode quebrar nosso código 
      // NÃO DELETAR ESSAS LINHAS COMENTADAS
      // const vectorId = vector.properties.id
      const vectorId = vector.properties ? vector.properties.id : vector.data.properties.id

      const latestAttributes = getModalData(vectorId, featureId);

      if (latestAttributes) {
        setSelectedFeatureAttributes(latestAttributes);
        setModalData([latestAttributes]);
        setModalFeatureId(featureId);
        handleModalOpen();
      }
    }
  };

  // Função para ser usada no onEachFeature
  const onEachFeatureVector = (vector) => (feature, layer) => {
    if (feature) {
      const featureId = feature.id;
      layerRefs.current[featureId] = layer;

      layer.on('click', (e) => {
        handleClickFeature(vector, feature, layer, e);
      });
    }
  };

  useEffect(() => {
    vectors.forEach((vector) => {
      if (vector.data && vector.data.features) {
        vector.data.features.forEach((feature) => {
          const featureId = feature.id;
          const layer = layerRefs.current[featureId];
          if (layer) {
            const updatedFeature = vector.data.features.find(f => f.id === featureId);
            if (updatedFeature) {
              layer.off('click'); // Remove listener existente
              layer.on('click', (e) => {
                handleClickFeature(vector, updatedFeature, layer, e);
              });
            }
          }
        });
      }
    });
  }, [vectors, layerRefs, setSelectedFeatures, setSelectedFeatureAttributes, setModalData, setModalFeatureId]);

  return { onEachFeatureVector };
};

export default useFeatureHandler;
