const SearchOption = ({vectors})=>{

    console.log("VECTORS INSIDE SEARCH",vectors)

    // const names = vectors.map((vector)=>{
    //     const name = vector.data.properties.name
    //     console.log(name)
    //     return(name)
    // })
    // console.log(names)

    return(
        <>
        
        </>
    )
}


export default SearchOption